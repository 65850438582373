import carrot from './orange.png'
import orange from './orangebunny.png'
import purple from './purplebunny.png'
import green from './greenbunny.png'
import yellow from './yellowbunny.png'
import home from './house.png'
import './App.css'
import Contact from './Contact'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Routes,
  Link,
} from 'react-router-dom'
import Main from './Main'

function App() {
  return (
    <Router>
      <div className="nav">
        <Link className="app__contact" to="/contact">
          CONTACT
        </Link>
        {/* <div id="terms" className="app__contact">
          TERMS
        </div> */}
        <h1>HUNGRY BUNBUNS</h1>
        <Link to="/">
          <img src={home} />
        </Link>
      </div>
      <Routes>
        <Route path="/contact" element={<Contact />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  )
}

export default App
