import React from 'react'
import logo from './logo.svg'
import hun from './hunbun.png'
import carrot from './orange.png'
import orange from './orangebunny.png'
import purple from './purplebunny.png'
import green from './greenbunny.png'
import yellow from './yellowbunny.png'
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

function Main() {
  return (
    <div>
      <div className="App">
        <div className="header">
          <header className="App-header">
            <img src={green} className="bun" alt="logo" />
            <div id="tes">
              <img id="left " src={orange} className="bun" alt="logo" />
              <img src={carrot} className="App-logo" alt="logo" />
              <img id="right" src={purple} className="bun" alt="logo" />
            </div>
            <img i src={yellow} className="bun" alt="logo" />
          </header>
        </div>
      </div>
    </div>
  )
}

export default Main
